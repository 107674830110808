import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
    imports: [CommonModule, ],
    providers: [],
})
export class SharedExtensionsModule {}
